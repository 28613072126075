import React, { type ReactElement } from 'react'

const NonAppLayout = ({ children }): JSX.Element => children

export const withNonAppLayout = (WrappedComponent): any => {
  const WithLayout = (props): any => <WrappedComponent {...props} />

  WithLayout.getLayout = function getLayout(page: ReactElement) {
    return page
  }

  return WithLayout
}

export default NonAppLayout
