import { type LoginAuthenticationResponse } from '@medplum/core'
import { useMedplum } from '@medplum/react'
import { Col, Row, Typography } from 'antd'
import React from 'react'
import { withNonAppLayout } from 'layouts/NonAppLayout'
import useSafeNavigation from 'hooks/useSafeNavigation'
import { captureException, SentrySources } from 'utils/sentry'
import { getErrorInstance } from 'utils/helpers'
import abbySplash from '../../public/assets/abbycaresplash.png'
import GoogleLogin from '../../components/Login/GoogleLogin'

const ALLOWED_ERROR_MESSAGES = ['Unauthenticated']

const Login = (): JSX.Element => {
  const medplum = useMedplum()
  const { safePush } = useSafeNavigation()

  function handleAuthResponse(response: LoginAuthenticationResponse): void {
    if (response.code !== undefined) {
      medplum
        .processCode(response.code)
        .then(() => {
          safePush('/')
        })
        .catch(
          (error) =>
            !ALLOWED_ERROR_MESSAGES.includes(error?.message) &&
            captureException(getErrorInstance(error), {
              tags: { source: SentrySources.LOGIN },
              extras: { info: { response } }
            })
        )
    }
  }

  return (
    <Row>
      <Col
        xs={{ span: 24 }}
        lg={{ span: 12 }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            width: 360,
            height: 122,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography.Title level={4}>Welcome to Abby Care EHR</Typography.Title>
          <Typography.Text type="secondary">Authorize with Google to continue.</Typography.Text>
          <GoogleLogin
            handleAuthResponse={handleAuthResponse}
            projectId={process.env.NEXT_PUBLIC_MEDPLUM_PROJECT_ID}
            // eslint-disable-next-line max-len
            googleClientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
          />
        </div>
      </Col>
      <Col
        xs={{ span: 0 }}
        lg={{ span: 12 }}
        style={{
          backgroundImage: `url(${abbySplash.src})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: '100vh'
        }}
      ></Col>
    </Row>
  )
}

export default withNonAppLayout(Login)
