import { type GoogleCredentialResponse, type LoginAuthenticationResponse } from '@medplum/core'
import { type OperationOutcome } from '@medplum/fhirtypes'
import { useMedplum } from '@medplum/react'
import React, { useState } from 'react'
import { GoogleButton } from './GoogleButton'

interface GoogleLoginProps {
  googleClientId: string | undefined
  projectId: string | undefined
  handleAuthResponse: (response: LoginAuthenticationResponse) => void
}

const GoogleLogin = (props: GoogleLoginProps): JSX.Element => {
  const medplum = useMedplum()
  const [, setOutcome] = useState<OperationOutcome>()

  return (
    <GoogleButton
      googleClientId={props.googleClientId}
      handleGoogleCredential={(response: GoogleCredentialResponse) => {
        medplum
          .startGoogleLogin({
            projectId: props.projectId,
            googleClientId: response.clientId,
            googleCredential: response.credential
          })
          .then(props.handleAuthResponse)
          .catch(setOutcome)
      }}
    />
  )
}

export default GoogleLogin
